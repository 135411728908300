import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeMenu() {
    const navbar = document.querySelector('.navbar-collapse');
    if (navbar) {
      // Remove the 'show' class to collapse the navbar
      navbar.classList.remove('show');
    }
  }

}
