import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"

const routes: Routes = [
  {
    path: ``,
    loadChildren: () =>
      import("./pages/home/home.module").then(m => m.HomeModule),
    canActivate: [],
  },
  {
    path: `contact`,
    loadChildren: () =>
      import("./pages/contact/contact.module").then(m => m.ContactModule),
    canActivate: [],
  },
  {
    path: `report/:id`,
    loadChildren: () =>
      import("./pages/report-details/report-details.module").then(
        m => m.ReportDetailsModule,
      ),
    canActivate: [],
  },
  {
    path: `categories`,
    loadChildren: () =>
      import("./pages/categories/categories.module").then(
        m => m.CategoriesModule,
      ),
    canActivate: [],
  },
  {
    path: `categories/report/:id`,
    loadChildren: () =>
      import("./pages/list/list.module").then(m => m.ListModule),
    canActivate: [],
  },
  {
    path: `about`,
    loadChildren: () =>
      import("./pages/about/about.module").then(m => m.AboutModule),
    canActivate: [],
  },
  {
    path: `status`,
    loadChildren: () =>
      import("./pages/order-status/order-status.module").then(
        m => m.OrderStatusModule,
      ),
    canActivate: [],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
