import { Component, OnInit } from '@angular/core';
import { CommonService } from './service/common.service';
import { DataService } from './service/data.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'market';

  constructor(private commonService: CommonService,
              private dataService: DataService,
              private router: Router
  ) {
  }

  ngOnInit(): void {
      this.dataService.getAppData().subscribe((data) => {
        this.commonService.apiKey = data.key;
      });

      this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to top when route changes
        window.scrollTo(0, 0);
      }
    });
  }
}
