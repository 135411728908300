import { Injectable } from '@angular/core';
import { Order } from '../models/oder.mode';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  apiKey: string;
  // tslint:disable-next-line:variable-name
  private _order: Order;
  constructor() {}

  get order(): Order {
    return this._order;
  }

  set order(value: Order) {
    this._order = value;
  }
}
