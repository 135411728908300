<!-- footer-28 block -->
<section class="w3l-market-footer">
  <footer class="footer-28">
    <div class="footer-bg-layer">
      <div class="container py-lg-3">
        <div class="row footer-top-28">
          <div class="col-md-6 footer-list-28 mt-5">
            <h6 class="footer-title-28">Contact information</h6>
            <ul>
              <li>
                <p><strong>Address</strong> : Moraya society Katraj</p>
              </li>
              <li>
                <p><strong>Phone</strong> : <a href="tel:+404-11-22-89">+404-11-22-89</a></p>
              </li>
              <li>
                <p><strong>Email</strong> : <a href="mailto:inquiry@tedresearchsolutions.com">inquiry@tedresearchsolutions.com</a></p>
              </li>
            </ul>

            <!--<div class="main-social-footer-28 mt-3">
              <ul class="social-icons">
                <li class="facebook">
                  <a href="#link" title="Facebook">
                    <span class="fa fa-facebook" aria-hidden="true"></span>
                  </a>
                </li>
                <li class="twitter">
                  <a href="#link" title="Twitter">
                    <span class="fa fa-twitter" aria-hidden="true"></span>
                  </a>
                </li>
                <li class="Instagram">
                  <a href="#link" title="Instagram">
                    <span class="fa fa-instagram" aria-hidden="true"></span>
                  </a>
                </li>
                <li class="Linkedin">
                  <a href="#link" title="LnkedIn">
                    <span class="fa fa-linkedin" aria-hidden="true"></span>
                  </a>
                </li>
              </ul>
            </div>-->
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 footer-list-28 mt-5">
                <h6 class="footer-title-28">Company</h6>
                <ul>
                  <li><a href="about.html">About</a></li>
                  <li><a href="#pricing.html">FAQ</a></li>
                </ul>
              </div>
              <div class="col-md-4 footer-list-28 mt-5">
                <h6 class="footer-title-28">Support</h6>
                <ul>
                  <li><a [routerLink]="['contact']">Contact Us</a></li>
                </ul>
              </div>
              <div class="col-md-4 footer-list-28 mt-5">
                <h6 class="footer-title-28">Popular categories</h6>
                <ul>
                  <li><a href="#URL">Agriculture</a></li>
                  <li><a href="#URL">Automobile & Transportation</a></li>
                  <li><a href="#URL">Chemical & Material</a></li>
                  <li><a href="#URL">Construction</a></li>
                  <li><a href="#URL">Consumer Goods</a></li>
                  <li><a href="#URL">Electronics & Semiconductor</a></li>
                  <li><a href="#URL">Energy & Power</a></li>
                  <li><a href="#URL">Food & Beverages</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="midd-footer-28 align-center py-lg-4 py-3 mt-5">
        <div class="container">
          <p class="copy-footer-28 text-center"> &copy; 2024 Market. All Rights Reserved. Design by <a
              href="https://w3layouts.com/">W3Layouts</a></p>
        </div>
      </div>
    </div>
  </footer>

  <!-- move top -->
  <button onclick="topFunction()" id="movetop" title="Go to top">
    &#10548;
  </button>
  <script>
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction()
    };

    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("movetop").style.display = "block";
      } else {
        document.getElementById("movetop").style.display = "none";
      }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  </script>
  <!-- /move top -->
</section>
