import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../constant/constants';
import { Category } from '../models/category.model';
import { Report } from '../models/report.model';
import { Page } from '../models/page.model';
import { ApiResponse } from '../models/api-response';
import { Observable } from 'rxjs';
import { Enquiry } from '../models/enquiry.model';
import { Order } from '../models/oder.mode';
import { AppData } from '../models/appdata.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getAllCategories() {
    return this.http.get<Category[]>(`${API_URL}/api/category`);
  }

  getReportByReportId(reportId: number) {
    return this.http.get<Report>(`${API_URL}/api/report/` + reportId);
  }

  reports$ = (categoryId: number, page: number = 0, size: number = 10): Observable<ApiResponse<Page>> =>
    this.http.get<ApiResponse<Page>>(`${API_URL}/api/report/category?categoryId=${categoryId}&page=${page}&size=${size}`)

  saveEnquiry(enquiry: Enquiry) {
    return this.http.post<Enquiry>(`${API_URL}/api/enquiry`, enquiry);
  }

  saveOrder(order: Order) {
    return this.http.post<Order>(`${API_URL}/api/order`, order);
  }

  getAppData(): Observable<AppData>  {
    return this.http.get<AppData>(`${API_URL}/api/data`);
  }

}


